import React, { useState, useEffect } from "react";
import style from "./carManagement.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CarDetails from "./car-details/CarDetails";
import DetailTable from "../../components/tables/DetailTable";
import getData from "../../components/services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { getCarList } from "../../store/car/carAction";
import { publicApi } from "../../api/publicApi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../components/buttons/multiSelect";

export default function CarManagement() {
  
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    vehicle_id: "",
    brand_id: "",
    model_id: "",
    variant_id: "",
    status: ""
  });
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);    
  const [totalPageCount, settotalPageCount] = useState();    
  const [totalCount, settotalCount] = useState();    
  const [hasNextPage, sethasNextPage] = useState();

  const [carList, setCarList] = useState([]);

  
  
  const [pagination, setPagination] = useState({
    pageSize : 1,
    pageIndex : 0
  })


  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      // console.log('response --->', res);
      
      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const models = async () => {
    try {
      const res = await publicApi.modelList({ brandId: filter?.brand_id });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ modelId: filter?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

 

  useEffect(() => {
    getCarList().then((response) => {
      setCarList(response.payload.data);
      setCurrentPage(response.payload.currentPage);
      settotalPageCount(response.payload.totalPageCount);
      console.log("response.payload.totalPageCount ", response.payload.totalPageCount);
      setPagination(response.payload.totalPageCount);
      settotalCount(response.payload.totalCount);
      sethasNextPage(response.payload.hasNextPage);

    }).catch((error) => {
      console.error('Error fetching car list:', error);
    });
    brands();
  }, []);



  useEffect(() => {
    if (filter?.brand_id) models();
  }, [filter?.brand_id]);

  useEffect(() => {
    if (filter?.model_id) varients();
  }, [filter?.model_id]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };


  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // const [data, setData] = useState(carList || []);

  // useEffect(() => {
  //   setData(carList);
  // }, [carList]);

  // const handleChange = (e) => {
  //   setFilter({ ...filter, [e.target.name]: e.target.value });
  // };

  const [isDetailPageVisible, setIsDetailPageVisible] = useState(false);

  // useEffect(() => {
  //   let filterData = carList;
  //   if (filter.vehicle_reg_no) {
  //     filterData = data?.filter((ele) =>
  //       ele?.vehicle_reg_no
  //         ?.toLowerCase()
  //         .includes(filter?.vehicle_reg_no?.toLowerCase())
  //     );
  //   }
  //   if (filter.brand_name) {
  //     filterData = data?.filter((ele) => ele?.brand_name == filter?.brand_name);
  //   }
  //   if (filter.model) {
  //     filterData = data?.filter((ele) => ele?.model == filter?.model);
  //   }
  //   if (filter.varient) {
  //     filterData = data?.filter((ele) => ele?.varient == filter?.varient);
  //   }
  //   if (filter.status) {
  //     filterData = data?.filter((ele) => ele?.status == filter?.status);
  //   }

  //   if (
  //     !filter.vehicle_reg_no &&
  //     !filter.brand_name &&
  //     !filter.model &&
  //     !filter.varient &&
  //     !filter.status
  //   ) {
  //     filterData = carList;
  //   }

  //   setData(filterData);
  // }, [filter]);

  // useEffect(() => {
  //   dispatch(
  //     getCarList({
  //       vehicle_id: filter?.vehicle_id || undefined,
  //       brand_id: filter?.brand_id || undefined,
  //       model_id: filter?.model_id || undefined,
  //       variant_id: filter?.variant_id || undefined,
  //       status: filter?.status || undefined,
  //       page : currentPage,
  //     })
  //   );
  // }, [filter]);

  useEffect(() => {
    const fetchFilteredCarList = () => {
      getCarList({
       vehicle_id: filter?.vehicle_id || undefined,
        brand_id: filter?.brand_id || undefined,
        model_id: filter?.model_id || undefined,
        variant_id: filter?.variant_id || undefined,
        dealerId: filter?.dealer_id || undefined,
        page: currentPage,
      }).then((response) => {
        setCarList(response.payload.data);
        settotalPageCount(response.payload.totalPageCount);
        settotalCount(response.payload.totalCount);
        sethasNextPage(response.payload.hasNextPage);
      }).catch((error) => {
        console.error('Error fetching filtered car list:', error);
      });
    };

    fetchFilteredCarList();
  }, [filter, currentPage]);
  

  const navigate = useNavigate();

  const exportToExcel = () => {
    const dataWithoutAction = carList.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "vehicle_data.xlsx");
    });
  };
  return (
    <div className={style["container"]}>
      {isDetailPageVisible && (
        <CarDetails
          onClick={() => setIsDetailPageVisible(!isDetailPageVisible)}
        />
      )}

      <PrimaryHeading
        title={"Car Management"}
        count={totalCount}
        fontSize={"1.75rem"}
        fontWeight={600}
      />

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search Vehicle No."}
            value={filter?.vehicle_id}
            handleChange={(e) =>
              setFilter({ ...filter, vehicle_id: e.target.value })
            }
            name="vehicle_id"
          />
          <MultiSelect
            name="brand_id"
            handleChange={(e) => setFilter({ ...filter, brand_id: e?.value, model_id: null })}
            items={brandList?.map((ele) => ({
              label: ele?.brand_name,
              value: ele?.id,
            }))}
            selectedItems={filter?.brand_id}
            hint={"All Brand"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
          <MultiSelect
            name="model_id"
            handleChange={(e) => setFilter({ ...filter, model_id: e?.value })}
            items={modelList?.map((ele) => ({
              label: ele?.description,
              value: ele?.id,
            }))}
            selectedItems={filter?.model_id}
            hint={"All Model"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
          <MultiSelect
            name="variant_id"
            handleChange={(e) => setFilter({ ...filter, variant_id: e?.value })}
            items={varientList?.map((ele) => ({
              label: ele?.description,
              value: ele?.id,
            }))}
            selectedItems={filter?.variant_id}
            hint={"All Varient"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
          <MultiSelect
            name="status"
            handleChange={(e) => setFilter({ ...filter, status: e?.value })}
            items={[
              { label: "Active", value: "Active" },
              { label: "InActive", value: "InActive" },
            ]}
            selectedItems={filter?.status}
            hint={"All Status"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Car"}
            fontSize={"1rem"}
            linkTo={"/add-new-car"}
            onClick={() => {}}
          />

          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>

      <DetailTable data={carList} />

      {/* <div className={style["table-pagination-container"]}>
        
        <div className={style["pagination-action-wrapper"]}>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Back
          </button>

          <p>
            Page {currentPage} of {totalPageCount}
          </p>

          <button onClick={handleNextPage} disabled={!hasNextPage}>
            Next
          </button>
        </div>
      </div> */}


        <div className={style["pagination-action-wrapper"]}>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Back
          </button>
          <p>
            Page {currentPage} of {totalPageCount}
          </p>

          <button onClick={handleNextPage} disabled={!hasNextPage}>
            Next
          </button>
        </div> 

    </div>
  );
}
