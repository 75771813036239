import { testimonials, getTestimonials } from ".";

class TestimonialApi {
    saveTestimonials(dealer_id, formData) {
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await testimonials(
                        "post",
                        `save-testimonials`,
                        dealer_id,
                        formData
                    );
                    console.log('response --->', res);
                    resolve(res);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    dropTestimonials(id){
        const params = {
            id
        }
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await getTestimonials(
                        "put",
                        `drop-testimonials`,
                        { params }
                    );
                    console.log('response --->', res);
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    updateTestimonials(dealer_id) {
        const params = { dealer_id };
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getTestimonials(
                    "get",
                    `get-testimonials`,
                    { params }
                );
                resolve(res.data);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }
    
    getTestimonials(dealer_id, pageIndex) {
        
        const params = {dealer_id, page : pageIndex}        
        
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await getTestimonials(
                        "get",
                        `get-testimonials`,
                        { params }
                    );
                    console.log('response --->', res);
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

}

export const testimonialsApi = new TestimonialApi();
