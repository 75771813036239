import React, { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./detailTable.module.scss";
import { useNavigate } from "react-router-dom";
import { R } from "../../constants/resources";

export default function DetailTable({ data = [] }) {
  const columns = [
    { accessorKey: "car_id", header: "Car Id" },
    { accessorKey: "vehicle_reg_no", header: "Vehicle No." },
    { accessorKey: "brand_name", header: "Make" },
    { accessorKey: "model", header: "Model" },
    { accessorKey: "variant", header: "Variant" },
    { accessorKey: "make_year", header: "Year" },
    { accessorKey: "driven_kms", header: "KM" },
    { accessorKey: "owner", header: "Owner" },
    { accessorKey: "fuel_desc", header: "Fuel" },
    { accessorKey: "transmission_desc", header: "Transmission" },
    { accessorKey: "actual_price", header: "Price" },
    { accessorKey: "discounted_price", header: "Sell Price" },
    { accessorKey: "discountPercent", header: "Disc" },
    { accessorKey: "status", header: "Status" },
    { accessorKey: "action", header: "Action" },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigate = useNavigate();
  const onEditClick = (data) => {
    navigate("/edit-car-details", { state: data });
  };

  const Action = (data) => (
    <div className={style["action-container"]}>
      <img
        src={R.ic_edit_pen}
        alt="ic_edit"
        onClick={() => onEditClick(data)}
      />
    </div>
  );

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr
                key={headerGroup?.id}
                className={style["table-header-wrapper"]}
              >
                {headerGroup?.headers?.map((header) => {
                  return (
                    <th key={header?.id} className={style["table-header"]}>
                      {header?.column?.columnDef?.header}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr key={row?.id} className={style["table-row"]}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {cell?.column?.columnDef?.accessorKey == "action" ? (
                        <Action data={row?.original} />
                      ) : (
                        <>
                          {flexRender(
                            cell?.column?.columnDef?.cell,
                            cell?.getContext()
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <div className={style["pagination-action-wrapper"]}>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Back
          </button>
          <p>
            Page {pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </p>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div> */}
    </div>
  );
}
