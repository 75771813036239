import { CREATE_CAR_DETAIL, EDIT_CAR_DETAIL, GET_CAR_LIST } from "../types";
import { carApi } from "../../api/carApi";

export const postCarDetails = (payload) => (dispatch) => {
  const formData = new FormData();
  Object.keys(payload).forEach((x) => {
    formData.append(x, payload[x]);
  });

  return carApi.postCarDetails(formData).then(
    (data) => {
      dispatch({
        type: CREATE_CAR_DETAIL,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// export const getCarList = (params) => (dispatch, getState) => {
//   //   const { buyerLeadList } = getState().buyerLead;
//   return carApi.getCarList(params).then(
//     (response) => {
//       // console.log('response-carlist -->', response?.totalPageCount)
//       dispatch({
//         type: GET_CAR_LIST,
//         payload: {
//           data: response?.data,
//           currentPage : response?.currentPage,
//           totalPageCount : response?.totalPageCount,
//           totalCount : response?.totalCount,
//           hasNextPage : response?.hasNextPage
//           // // buyerLeadList && params?.page > 1
//           // //   ? [...buyerLeadList, ...response?.data]
//           // //   : response?.data,
//           // total: response?.total,
//         },
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

export const getCarList = (params) => {
  // console.log("params ", params);
  return carApi.getCarList(params).then(
    (response) => {
      // console.log("checkRespnse ", response);
      return {
        type: GET_CAR_LIST,
        payload: {
          data: response?.data,
          currentPage: response?.currentPage,
          totalPageCount: response?.totalPageCount,
          totalCount: response?.totalCount,
          hasNextPage: response?.hasNextPage,
        },
      };
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const editCarrDetails = (payload) => (dispatch) => {
  console.log(payload);
  const formData = new FormData();
  Object.keys(payload).forEach((x) => {
    formData.append(x, payload[x]);
  });

  return carApi.editCarDetails(formData).then(
    (data) => {
      dispatch({
        type: EDIT_CAR_DETAIL,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
